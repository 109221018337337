interface SongsByCategory {
    category: string;
    items: string[];
}

export const songbook: {
    title: string,
    subtitle: string,
    footnote: string,
    songs: SongsByCategory[],
} = {
    title: 'Repertoar',
    subtitle: 'Pronađite svoju najdražu pjesmu u bogatom repertoaru Viva Banda',
    footnote: `Popis nije konačan - znamo mi još puno pjesama koje ovdje nisu navedene. 
               A priznajemo i da bismo se nekih od navedenih trebali malo prisjetiti… :)`,
    songs: [
        {
            category: 'Svatovske',
            items: [
                'KRALJICA U BIJELOM',
                'NE PLAČI MAJKO ZA KĆERKOM SVOJOM',
                'LJUBAVI JEDINA',
                'HAJ DOŠLO VRIJEME',
                'SIJEM ŽITO',
                'TI SI RADOST MOG ŽIVOTA',
                'JA TI ŽELIM SVAKI DIVAN DAN',
                'VJENČANE RUŽE',
                'JA SE KONJA BOJIM',
                'PLAVI JORGOVANI',
                'RUŽMARINE MOJ ZELENI',
                'ŠIROK ŠOR',
                'OTVOR ŽENO KAPIJU',
                'SVEKRVA',
                'HEJ MATI MATI',
                'REKLA NANA',
                'VESELA JE ŠOKADIJA',
                'ZAGRLI ME NJEŽNO',
                'PRVI POLJUBAC',
                'STAROM CESTOM',
                'TREPETILJKA',
                'TOČI VINA OČE STARI',
                'OVA NOĆ',
                'DVA PRSTENA',
                'DANIJELA',
                'STARI RIBAR',
                'PO SELU SE PRIČA',
                'BIJELU BLUZU SUZA KVASI',
                'OČI BOJE KESTENA',
                'ŽENO NAJLJEPŠA',
                'DANAS MAKO ŽENIŠ SINA',
                'HEJ NE OKREĆI GLAVU',
                'KAD BI ZNALA MILA KĆERI',
                'KAD SE SMIJEŠ MILA KĆER',
                'NEVJESTA (Halid M.)I',
                'VEČERNJA ZVONA',
                'JA SE ŽENIM (Patria)'
            ]
        },
        {
            category: 'Narodne',
            items: [
                'OVA LJUBAV BAŠ PRETJERUJE – Dejan Matić',
                'ZLATNE ŽICE – Hanka Paldum',
                'TRI ČAŠE – Milica Todorović',
                'TI SI ĆERKO TATIN SIN – Nino',
                'I KAD ME SVI ZABORAVE – Marinko Rokvić',
                'BOGOVI ZEMLJOM HODE – Ljuba Aličić',
                'O TEBI SE ŠAPUĆE – Dušica Milojević',
                'NAĐI NOVU LJUBAV – Saša Matić',
                'ONA JE MOJA – Dejan Matić',
                'VRATI MI SE NESREĆO – Miligram 3',
                'KLEO SE, KLEO – Snežana Đurišić',
                'UČINIT ĆU SVE – Snežana Đurišić',
                'STARI LAV – Željko Samardžić',
                'PUTNICKA - ĐENAN LONČAREVIĆ',
                'LAZU TE - AMADEUS BAND',
                'KAD BI BIO RANJEN -CECA',
                'PARIŠKE KAPIJE -HARIS',
                'HLADNO JE UGRIJ ME-HARIS',
                'LAZE MJESEC- HARIS',
                'PALIM ZADNJU CIGARETU - MITAR MIRIC',
                'DOBRO JUTRO REKOH ZORI - MITAR MIRIC',
                'NOCI U SIBIRU -MATIC SASA',
                'MOZDA SMO I MI -KOSMAJAC',
                'NEZNAM JA DA MUSKI POGINEM- MATIC',
                'MIKI MIĆO - BRENA',
                'SANJAM - BRENA',
                'HAJDE DA SE VOLIMO - BRENA',
                'KOLOVODJA - BRENA',
                'PITAJU ME U MOM KRAJU- DRAGANA MIRKOVIĆ',
                'KADA BI ME PITALI- ZORICA',
                'KRALJICE MOGA SRCA- ŠABAN ŠAULIĆ',
                'ŽAL -ŠABAN ŠAULIĆ',
                'S NAMEROM DOĐOH U VELIKI GRAD-ŠABAN',
                'JOS OVU NOĆ -ŠABAN',
                'LAVANDA (Halid Bešlić)',
                'SIJEDE (Halid B.)',
                'TE SAM NOĆI PREVARILA SEBE (I. Šaulić)',
                'ŠTO SVAKA ŽENA SANJA (H. Paldum)',
                'VOLI ME I ČUVAJ ME (Jelena)',
                'EVO MENE (D. Matić)',
                'ZOVITE MI TAMBURAŠE (R. Urošević)',
                '5 MINUTA (N. Kujundžić)',
                'U GODINI JEDAN DAN (D. Filipović/T. Savić)',
                'LAGAT ĆU A PLAKAT ĆU (Maja Marijana)',
                'DAJTE VINA HOĆU LOM (H. Džinović)',
                'NOĆAS RAJ SUTRA KRAJ (M. Ilić)',
                'JA TE PJESMOM ZOVEM (H. Paldum)',
                'POLOMT ĆU ČAŠE OD KRISTALA (M. Ilić)',
                'ZAMKA (Bon Ami)',
                'VESELJAK (A. Bekuta)',
                'KORITA IVANOVA (M. Njegomir)',
                'ZRNO MUDROSTI (Džej)',
                'BOLNE GRUDI OTROVANE (H. K. Koki)',
                'RESKIRAM (M. Ilić)',
                'HEJ KORANO TECI (Desperado 037)',
                'MOJ BEHARU (N. BILKIĆ)',
                'NE DUGUJEM NIKOM NIŠTA (CANE)',
                'OVO JE PRIČA O NAMA (A. Dimov)',
                'PRSTEN NA TVOJOJ RUCI (Tozovac)',
                'VOLJENA SI MENE U POGREŠNO VRIJEME',
                'VOLIT ĆU TE DO KRAJA ŽIVOTA',
                'TA ŠTO ME JE VOLJELA',
                'TREBA VREMENA',
                'BRAT I SESTRA',
                'IZ OČIJU SUZA TEČE',
                'VJERNA SAM TI POPUT SLUGE (SANJA ĐORĐEVIĆ)',
                'PRIVJESAK OD ZLATA (CONDORI)',
                'MED I SLATKO GROŽĐE (ROKVIĆ)',
                'POKAŽI SVOJE ZMIJSKO TIJELO (BAJA)',
                'MITO BEKRIJO (VASILIJA RADOJČIĆ)',
                'JANJEVO / BITOLA MOJ RODEN KRAJ (TRAD.)',
                'JOVANO, JOVANKE (TRAD.)',
                'EJ OTKAD SAM SE RODIO (SINAN SAKIĆ)',
                'OKREĆE SE KOLO SREĆE (KEMAL MALOVČIĆ)',
                'ZOKO, MOJA ZOKO (SINAN SAKIĆ)',
                'SUDBINA ME NA PUT ŠALJE (SINAN SAKIĆ)',
                'JA JOŠ UVIJEK KAO MOMAK ŽIVIM',
                'GORI BOROVINA',
                'DIJANA LJUBAVI',
                'ZBOG MIRJANE',
                'ZELENE OČI',
                'PLAVA ŽENA',
                'ZAPALIT ĆU POLA GRADA',
                'PUT ME ZOVE',
                'NE SVANI ZORO MLADA',
                'JANA',
                '700 DANA',
                'MALO PO MALO',
                'NOĆNA PTICA',
                'DOJADILO MOJ LUDI ŽIVOTE',
                'IVANA, IVANA',
                'ODE KUĆA, ODE BRAK',
                'ŽENA NEVJERNA',
                'DOČEKAT ĆU S TOBOM STOTU',
                'ŽENA PREKO PUTA',
                'CRVEN KONAC',
                'ŽIVIM',
                'JA VRAGOLAN I MOJ DJEDA',
                'SUNCE I ZORA',
                'BEĆAR ZA SVA VREMENA',
                'SUVIŠE SAM MLAD',
                'HEJ LJUBAVI U DALEKOM GRADU',
                'BJEŠE JEDNA ŽENA',
                'PREŠLO MI U NAVIKU',
                'SANELA',
                'HEJ SUZO MOJA',
                'OPET SAM POGRIJEŠIO',
                'KOLIBA IL DVOR',
                'IDI, IDI',
                'ŽELJEN SAM, ŽELJEN',
                'NIŠTA NIJE SLUČAJNO',
                'SEDAM ČAŠA',
                'VRATI ME U ŽIVOT',
                'LJUBAVNIK NA DUGE STAZE',
                'HEJ, BEĆARI,',
                'KRAJ DUNAVA KOLIBA',
                'SVE SU ČAŠE POLOMLJENE',
                'PLAČEM JA PLAČEŠ TI',
                'KAD TE DRUGI OSTAVE',
                'ANĐELE MOJ',
                'OČI BOJE KESTENA',
                'ŽALIT ĆE KAFANE',
                'BROJANICA',
                'PAS BEZ LANCA',
                'IMAO SAM LJUBAV JEDNU',
                'NISAM ČOVJEK KOJI PLAČE',
                'MANITE SE LJUDI',
                'ČAŠA OTROVA',
                'ODAVDE DO ZAGREBA',
                'DA TI BUDEM DJEVOJKA',
                'STARIŠ A NE OSJETIŠ',
                'AMAJLIJA',
                'TAMBURAŠI, DUŠA BOLI',
                'ZA PROŠLU LJUBAV',
                'OF JANO JANO',
                'KAFANA JE MOJA SUDBINA',
                'ŠTO JE ŽIVOT BEZ LJUBAVI',
                'SEDAM VIOLINA',
                'BOŽANSTVENA ŽENO',
                'KLJUČ SRCA MOG',
                'JEDAN POLJUBAC (KOCKA ŠEĆERA)',
                'IZ INATA',
                'HEJ POŠTARU',
                'MJESEC TOČI ZRNO BISERA',
                'MOJA SI MOJA',
                'JANA 2',
                'ROĐENDANSKA',
                'DVA PRSTENA',
                'MALO SLAĐA MALO ZORKA',
                'NE ZNA ONA',
                'DEVET DANA',
                'PITAO SAM PROLAZNIKE',
                'LELE, LELE',
                'POSLJEDNJI BOEM',
                'TEŠKO JE MENE VOLJETI',
                'ČARDAK',
                'DVADESETE',
                'MALO JE, MALO DANA',
                'MILJACKA',
                'IZNAD TEŠNJA',
                'ŽELJO MOJA',
                'PRVI POLJUBAC',
                'SKITNICA',
                'ŠTA ĆE MI SUBOTE',
                'POKLONITE MI NJU ZA ROĐENDAN',
                'TRI U JEDNOJ',
                'USNE BOJE VINA',
                'ŠAMPANJAC',
                'PRVI SASTANAK',
                'LIJEPA KAO GRIJEH',
                'SUNCE SE RAĐA',
                'ZELENA',
                'EVO SNJEŽO OPET SNJEGOVA',
                'HEJ ZORO NE SVANI',
                'ZAIGRAJ',
                'KUKAVICA',
                'TEBI MOJA MAZO',
                'SVE JE MOJE TVOJE',
                'OFICIR S RUŽOM',
                'CIGANČE',
                'ONA, ONA',
                'MA NIJE ZORA',
                'MOJE NAJMILIJE',
                'NEDIRAJ ONOG ČOVJEKA',
                'KONOBARI',
                'I TEBE SA SIT KAFANO',
                'ŽIVJELA LJUBAV',
                'MOJ JARANE JARANE',
                'LJUBILA JE PRVI PUT',
                'SUZO MOJA SUZANA',
                'UGASILA SI ME',
                'SRCE JE MOJE VIOLINA',
                'ŽENA PRIJATELJA MOG',
                'JA VOLIM MAJKO CIGANKU',
                'FATA',
                'BRE GIDI ĐANU',
                'RAMO, RAMO',
                'KAKO MI NEDOSTAJEŠ',
                'OSTARIT ĆU',
                'POZNAT ĆEŠ ME I PO MRAKU',
                'DJEVOJKA IZ GRADA',
                'POKIDAĆU LANCE',
                'TEBI',
                'PUSTI ME DA JE VIDIM',
                'DA VOLIM DRUGU NEMOGU',
                'TISUĆU SUZA MAJKO',
                'TI SI ME ČEKALA',
                'TAKO MI NEDOSTAJEŠ',
                'POZDRAVI JE POZDRAVI',
                'NIJE ŽIVOT JEDNA ŽENA',
                'TO LUDO SRCE MOJE',
                'JEDAN DAN ŽIVOTA',
                'SAMO TI',
                'ISPLAČI SE NA GRUDIMA MOJIM',
                'JEDNA ŽENA A DVA DRUGA',
                'PRIKLETA JE AMERIKA',
                'NESTAT ĆU KO ROSA',
                'ŠTA LI TI JE UČINILO VRIJEME',
                'NALJUTIT ĆEŠ ME TI',
                'VOLIM TE ŽIVOTE',
                'ZAŠTO MOJA DUŠA PATI',
                'TI SI MOJA SLATKA TAJNA',
                'PUSTI LJUBAV NEKA KRENE',
                'HEJ BEĆARI NE GUBITE VRIJME',
                'NISAM TE SE NAGLEDAO',
                'NE MOGU DA TE ZABORAVIM',
                'KAO PRAVA FAMILIJA',
                'AKO TI JE DUŠA ČISTA',
                'PISALA MI STARA MATI',
                'ZVAO SAM JE MALENA',
                'KAKO SADA ŽIVIŠ',
                'CRVENO OBUCI',
                'LUCKASTA SI TI',
                'ZBOGOM DRAGA',
                'EJ KAVANO',
                'ZDRAVO GAZDA',
                'U BAŠTICI MOJOJ',
                'SKELEĐIJO NA MORAVI (MOŽE I KORANI)',
                'TRI METERA SOMOTA',
                'SIJEDOKOSI',
                'DVIJE SUZE IZ 2 OKA',
                'DOZVOLI MI DA TE ZABORAVIM',
                'LITAR VINA DAJ',
                'VRATI SE STARIM PUTEM',
                'SIJEDI BEĆAR',
                'VODENIČARKA',
                'IDEM KUĆI VEĆ ZORA',
                'TIHO KUPA NOĆAS TEČE',
                'KUĆO STARA',
                'SVE JE KRIVA NJENA MAJKA',
                'IDEM SELOM',
                'NE GLEDAJ U MENI DRUGA',
                'KAVANA JE ZATVORENA',
                'VOLI ME DANAS',
                'NAJLJEPŠE SU PLAVE ŽENE',
                'MOSTOVI NA KORANI',
                'OPET ME JE PREVARILO PIĆE',
                'DUNAVE',
                'MAĐARICA',
                'ZVIJEZDA TJERA MJESECA',
                'SVE BEHARA',
                'NE DAJ DA NAS RASTAVE',
                'MARA MARAMICA',
                'LIJEPOTICA OD SIROTANA',
                'DJEVOJKA JE ZELEN BOR SADILA',
                'ZELENE SU OČI',
                'LJUBIO SAM U SELU KRAJ SAVE',
                'ČOVJEK IZ NARODA',
                'KAD SUZE NAJAVE KRAJ',
                'VINO TOČIM A VINO NE PIJEM',
                'ŠTO JE ŽIVOT KAD SE MRIJETI MORA',
                'TI SI SRETNA ŽENA',
                'LICE JEDNE ŽENE',
                'KRIVO MI JE KRIVO JE',
                'VOLIŠ LI ME KAO NEKAD',
                'PLAČI AL DIGNI GLAVU',
                'PRIJATELJI',
                'DVIJE DUŠE RANJENE',
                'DOVIĐENJA DRUŠTVO',
                'OPROSTI ZA OBEĆANJE',
                'PROĐE LJETO TRIDESETO',
                'LANČIĆ MALI',
                'SKINI PRSTEN S DESNE RUKE',
                'OČI SU MOJE MUTNE OD DIMA',
                'IDI MALA IDI',
                'BOBANA',
                'JA NE PLAČEM',
                'NE VRAĆAM SE DVAPUT NA POČETAK',
                'OBOJE SMO TVRDOGLAVI',
                'NA STAROM MOSTU',
                'PAMTIT ĆU TE',
                'GRIJEŠNICA',
                'LJUBIO SAM NISAM ZNAO',
                'TKO TE LJUBI U SUBOTNJE VEČE',
                'LJILJANI',
                'VOLIMO TE JA I MOJA MAMA',
                'MENE JE UČILO VRIJEME',
                'SJEĆAŠ LI SE ONE NOĆI',
                'BEZ TEBE JE GORKO VINO',
                'NIŠTA OD NAS NEĆE BITI',
                'NEMOJ TAKO HEJ SVIRAČU',
                'MLADOST JE OTIŠLA',
                'HEJ EVO ME',
                'ZLATNA ČAŠA',
                'NE DAJ SE GENERACIJO',
                'RASTAJE SE MOJA GENERACIJA',
                'KUNEM SE',
                'NE DIRAJ ONOG ČOVJEKA ZA STOLOM',
                'ZAKUNI SE, MOJA ZVIJEZDO SREĆE',
                'IZAĐI MALA',
                'ZUMBULI SU PROCVALI',
                'ZNAM ZA SVE SAM KRIV',
                'IDI MORAM DA TI KAŽEM',
                'PUTUJ, PUTUJ SREĆO MOJA',
                'POSLIJE TEBE NEĆE',
                'POGLEDAJ ME JEDNOM NJEŽNO',
                'ČUVAJ PRVU LJUBAV',
                'HEJ MLADOSTI - HEJ ŽIVOTE',
                'PJEŠKE IDEM IZ GRADA',
                'PADALA JE KIŠA',
                'OŽENJEN SAM KAO MOMAK ŽIVIM',
                'JEDNO SRCE ZALJUBLJENO',
                'AKO TE UPITA NEKO',
                'PRISTAJEM NA SVE',
                'ONA NIJE KAO DRUGE',
                'DOBRO VEČE IZGUBLJENA NADO',
                'OČI BOJE ZELENE',
                'SMIRILA SI LUTALICU',
                'MOJ BAGREME BIJELI',
                'TEBI MAJKO MISLI LETE',
                'ZARASLE SU STAZE MOJE',
                'PROĐOH BOSNOM',
                'SVE BEHARA',
                'OJ SAFETE SAJO',
                'ŠTA ĆU KUĆI TAKO RANO',
                'SEDAMDESET I DVA DANA',
                'U PRIJEDORU POKRAJ SANE',
                'HARMONIKO MOJA',
                'ŠOFERSKA JE TUGA PRE GOLEMA',
                'OTVOR PROZOR',
                'TRI LIVADE NIGDJE HLADA NEMA',
                'ZNAŠ LI DRAGA ONU ŠLJIVU RANKU',
                'NE SILAZI SA ČARDAKA',
                'JUTROS MI JE RUŽA PROCVIJETALA',
                'OJ LIVADO ROSNA TRAVO',
                'STARA KUĆA',
                'ČIJA GORA IVO',
                'STADE SE CVIJEĆE ROSOM KITITI',
                'NA KRAJU SELA ČAĐAVA MEHANA',
                'JEDNA RIJEKA U MOM KRAJU',
                'KRČMA U PLANINI',
                'ŠOTA',
                'DJEVOJKA SOKOLU',
                'AH MOJ ALJO',
                'U LIJEPOME VIŠEGRADU',
                'NEMA RAJA',
                'PJEVAJ SESTRO PJEVAJ BRATE',
                'BRANKA PRIJEDORĆANKA',
                'BANJA LUKO',
                'NON STOP (BAJA)',
                'DOĐI DA TI DAM (ĆIRA)',
                'FENOMENOMENALNO',
                'MOJE IME NE SPOMINJI VIŠE (DYNAMIC)',
                'SIROTANI',
                'IVANOVA KORITA',
                'NE TOČITE MLADO VINO',
                'VOLIM TE SVE VIŠE',
                'STARI SE (Š. Konjević)',
                'U TVOM SRCU JA, U MOM SRCU TI (LUTALICE)',
                'JA SAM MOMČE IZ TRAVNIKA',
                'LAGAT ĆU, A PLAKAT ĆU (MAJA MARIJANA)',
                'ONU MOJU (Tropico)'
            ]
        },
        {
            category: 'Tambure i Domaće',
            items: [
                'SOKAK BEZ IMENA (Miroslav Škoro)',
                'ZADNJA ŽELJA (Miroslav Škoro)',
                'ĆAĆA (Vlado Bašić)',
                'OBLAK MALI (TS Salaš)',
                'EJ ŽIVOTE TEŽAK LI SI (TS Fijaker)',
                'IMAM KUMA NEMA GA DRŽAVA (TS Salaš)',
                'SVIRAČI SU IZ MOG SELA (Drvenjaki)',
                'LJUBE SE DVA GOLUBA (Sjepan Jeršek Štef)',
                'KAO VJETRI SJEVERNI (TS Fijaker)',
                'KUĆA MALA, KUĆA SIROTANA (TS Fijaker)',
                'JA VOLIM I ŽIVIM (Stjepan Jeršek Štef)',
                'ŽIVIM I UŽIVAM (Hraščanski čestitari)',
                'VJEČNI NEŽENJA (Laganini band)',
                'KAD MI PADNE ŠEŠIR NA ČELO (Štef)',
                'OKO GARAVO (Širok šor)',
                'ELVIS JE ZAGOREC BIL (Podvinčani)',
                'KONJI BILI, KONJI VRANI (Škoro)',
                'MOJ JE DADO PUNO RADIO (Lole)',
                'VOZI SKELA (Štef)',
                'ŠTA KOG BRIGA (Sl. lole)',
                'TERAJ ŽESTOKO (M. Škoro i Sl. lole)',
                'POD BRAJDE (PODVINČANI)',
                'SPOVED (GUBEC)',
                'DOBRO MI DOŠEL PRIJATEL (VUKOV)',
                'SUZA ZA ZAGORSKE BREGE (VUKOV)',
                'VJEČNI SOKOL',
                'MENE ZOVE KRUŠKOVAČA',
                'PJEVAJ MI SOKOLE',
                'MOJA JE KUĆA NA KRAJU SELA',
                'ORAJE, ORAJE',
                'NA MALENOM BRIJEGU',
                'GARAVUŠO GARAVA',
                'ŽELJA MI JE',
                'PRIJATELJU NIJE LAKO',
                'MOSLAVINO DRAGA',
                'MOSLAVAČKI KRAJ',
                'JA SAM MOMAK BILOGORAC',
                'MOSLAVAČKO DIJETE',
                'MOJ GARAVI GOTOVO JE',
                'JUNAK IZ DOLINE',
                'JEDNO JUTRO JA URANI',
                'JA SAM ROĐEN U RAVNICI',
                'GOLUBICE BIJELA',
                'KRAJ JEZERA',
                'KONJI VRANI',
                'KARAŠICA',
                'JA POLAKO PIJEM',
                'PODRAVINO MOJA MILA',
                'ALA JE DIVAN TAJ PODRAVSKI KRAJ',
                'POTOČIĆ MALENI',
                'PODRAVINI DAO SI SRCE',
                'FALA',
                'TIĆEKI MALI',
                'VESELO, VESELO ZAGORCI',
                'PROŠLE SU MNOGE LJUBAVI',
                'NE DIRAJTE MI RAVNICU',
                'SVE BI DAO DA ME ONA VOLI',
                'ŠUMI, ŠUMI JAVORE',
                'MOJA JULIŠKA',
                'ODAVNO SMO GRANIČARI STARI',
                'LEPE TI JE ZAGORJE ZELENE',
                'KAD ČUJEM TAMBURE',
                'SEOSKA SAM LOLA',
                'KAD PROCVATU JABUKE',
                'VELA LUKA',
                'KONOBO MOJA',
                'MASLINA JE NEOBRANA',
                'MATA',
                'KO TE IMA TAJ TE NEMA',
                'OSAM TAMBURAŠA S PETROVARADINA',
                'FIJAKER STARI',
                'GOVORI SE DA ME VARAŠ',
                'RANE DEVEDESETE',
                'ČAŠA GORKIH SUZA',
                'MAMA HUANITA',
                'MI IMAMOS MNOGOS PROBLEMOS',
                'ZDRAVO GAZDA JOZA',
                'JA SAM GAZDA JOZA',
                'ČERVENA SUKNJIČKA',
                'KRISTINKO',
                'ŠUP SEM, ŠUP TAM',
                'DAJ DOĐI MI',
                'KAD POLETIŠ MALA PTICO',
                'OTIŠ\'O JE S MIRISIMA JUTRA',
                'PIJEM DA ZABORAVIM',
                'A SAD ADIO',
                'DVA ZAVIČAJA',
                'HRVATICA JA SAM MLADA',
                'CROATIO IZ DUŠE TE LJUBIM',
                'GRADE KRAJ RIJEKE',
                'KONJI VRANI',
                'DAO BI ZLATNE DUKATE',
                'LISTAJ LIPO STARA',
                'OSTAVIT ĆU TAMBURE',
                'ŽIVIM ŽIVOT KO SKITNICA',
                'KIŠA PADA',
                'ZAGORSKI CUG',
                'SVE BIRTIJE OBIŠ\'O SAM',
                'LAŽE SELO, LAŽU LJUDI',
                'NEĆE SNAŠA TAMBURAŠA',
                'ŽIVI ŽIVOT I UŽIVAJ',
                'SERBUS DRAGI ZAGREB MOJ',
                'PAR KORAKA OD MERAKA (TS Fijaker)',
                'OD ŽIVOTA SVE MI UZMITE (TS Fijaker)',
                'SASTALA SE BRAĆA (Slavonske lole)',
                'DUŠA BEĆARSKA (Slavonske lole)',
                'OJ, GORO BILOGORO (Franjo Barić i Podravski mužikaši)',
                'UNUK (Kočije)'

            ]
        },
        {
            category: 'Zabavne',
            items: [
                'Normalna (Jasmin Stavros)',
                'Divna (Vigor)',
                'Da sam ja on (Vigor)',
                'Lutka (S.A.R.S.)',
                'Zavela me Ana (Mejaši)',
                'Dolazi trideseta (Begini)',
                'Karanfili (Nives Drpić)',
                'Doživotno tvoj (Posegana)',
                'Kada žene tulumare (Jole)',
                'Tika taka tika fak (Vatrogasci)',
                'Volio BiH (Dubioza kolektiv)',
                'Jedina (Zdravko Čolić)',
                'Uno momento (Severina)',
                'Gdje sam bio (Bebek)',
                'Uzbuna (Severina)',
                'Whiskey (L. Bačić)',
                'Napala me s leđa - Begini',
                'Vjeruj u ljubav - Oliver',
                'Pred tvojim vratma - Oliver',
                'Samo je ljubav tajna dvaju svjetova- Thompson',
                'Oko moje sanjivo - Magazin',
                'Ti si mi u mislima- D. Dvornik',
                'Ljubav jedina -Dalmatino',
                'Kad zamirisu jorgovani',
                'Hajde nocas mi dodi - Merlin',
                'Ucini mi pravu stvar- Merlin',
                'Pjevam danju pjevam noću – Čolić',
                'Linđo - Gracia',
                'Tek je 12 sati - ET',
                'Još fališ (Vigor)',
                'Vozačka dozvola (Lidija Bačić)',
                'Želim te noćas (Bosutski bećari)',
                'Tarapana (Severina)',
                'Za sve moje bivše (Begini)',
                'Bolji nego ikad (Mejaši)',
                'Zorica (Mejaši)',
                'Naučila si me (I. Zak)',
                'Zbogom pameti (I. Zak)',
                'Tche tchetcherere – Balada boa / stari golf (Gusttavo Lima)',
                'Dobitna kombinacija (Jelena Rozga)',
                'Brodolom (Danijela Martinović)',
                'Italiana (Severina)',
                'Mjesečina (Merlin)',
                'Raduje me, raduje (Slavonija band)',
                'Van iz grada (Belfast food)',
                'Pobjego sam (Opća opasnost)',
                'Ako me ostaviš (M. Kovač)',
                'Tko mi te krade (I. Zak)',
                'Moji drugovi (Bajaga)',
                'Tri curice male (Roboti)',
                'Anđele moj (O. Dragojević / klapa Kampanel)',
                'Prva ljubav (Đ. Balašević)',
                'Bježi kišo s prozora (Crvena jabuka)',
                'To mi radi (Crvena jabuka)',
                'Zovu nas ulice (Crvena jabuka)',
                'Tražena si roba u gradu (Vuco)',
                'Daj šta daš (J. Rozga)',
                'Košulja bila (M. Grdović)',
                'Budi noćas mirno more (Đ. Stipaničev)',
                'Maslačak (Magazin)',
                'Razmažena (Jelena Rozga)',
                'Brad pitt (Severina)',
                'Drska ženo plava (Ž. Joksimović)',
                'Sad kada došla si (Klapa Cambi i Bosutski bećari)',
                'Rodit ću ti kćer i sina (Jelena Rozga)',
                'Kemija (Magazin)',
                'Ai se su te pego (Michel Telo)',
                'Srdele (Doris Dragović)',
                'Ej lutkice (Slavonija band)',
                'Još jedan dan bez nje (Slavonija band)',
                'Bolja od najbolje (I. Zak)',
                'Zora bila (Intrade)',
                'svatovska (Maja Šuput)',
                'ja virujen u anđele (Oliver)',
                'odijelo (Jole)',
                'da se nađemo na pola puta (Neda)',
                'jedan život malo je (prva liga)',
                'moj lipi anđele (Oliver)',
                'jablani (M. Bulić)',
                'nisam te znao voljeti (N. Prša)',
                'pjevam danju, pjevam noću (Čolić)',
                'Moje zlato (Petar Grašo)',
                'Ljubavnice (Joy)',
                'Dobro da nije veće zlo (Lexington)',
                'Volio BiH (Dubioza kolektiv)',
                'Skopje Beograd (Željko Joksimović i Danijel)',
                'Megamix (Dalibor Brun)',
                'Moja Ane broji dane (Milo Hrnić)',
                'Ne mogu ja bez tebe (Ivan Mikulić)',
                'Svirajte mi jesen stiže dunjo moja (Đorđe Balašević)',
                'zar je voljeti grijeh (kap po kap)',
                'da sam te sreo ranije',
                'ne daj da te slome',
                'stani srce',
                'neću',
                'žena kao ti',
                'samo anđeli znaju',
                'evo noći evo ludila',
                'oj curice moja milena',
                'naša ljubav od svega je jača',
                'mnogo značiš za moj život draga',
                'godine ne brojim',
                'vladarica',
                'jazz ba',
                'nitko nema 2 života',
                'ljubav nosi tvoje ime',
                'neka, neka',
                'mene grije',
                'dobro mi došla stara ljubavi',
                'kad čuješ tambure',
                'divno je biti nekome nešto',
                'drugi joj raspliće kosu',
                'majko stara',
                'hej ljubavi stara',
                'jedna jedina',
                'lijepa vila',
                'zvone zvona',
                'kralj',
                'što si mi sinoć stavila u vino',
                'mađarica',
                'da te ne volim',
                'krivi spoj',
                'uberi ljubicu - trava zelena',
                'fešta - tri sulara - smiljaniću',
                'da te mogu pismom zvati',
                'morska vila',
                'Dalmatinac sam',
                'Bili su bili vrhovi planina',
                'Ništa kontra Splita',
                'moj dida i ja... i još njih od Thompsona',
                'stojim na kantunu',
                'blue suede shoes',
                'pokraj sela čađava mehana',
                'što si me majko rodila',
                'ti si moja ljubav stara',
                'put putujem',
                'što si ti meni',
                'lijepa li si',
                '14 palmi',
                'bijela boja',
                'jednina i množina',
                'zove me jole',
                'sve bi seke ljubile mornare',
                'pitaj boga, smotala si me, šinjorina',
                'nedjelja',
                'marina',
                'zenica blues',
                'ja volim samo sebe',
                'ako su to bile laži',
                '17 ti je godina',
                'hajde da ludujemo',
                'odavno više ne plačem',
                'da nije ljubavi',
                'kad prođe sve',
                'ako varaš',
                'što si mi sinoć stavila',
                'nitko mene nije tjerao',
                '100 i jedan život',
                'golubice bijela',
                'konobo moja',
                'ako me ostaviš',
                'to ludo srce moje',
                'Ne daj da te slome',
                'Pisma ljubavna',
                'Svjedok',
                'Tetovaža',
                'Policija trenira strogoću',
                'Đurđevdan (Bijelo dugme)',
                'Lipe cvatu(Bijelo dugme)',
                'Jer to je mala moja rock and roll',
                'Motori',
                'Dinamo ja volim',
                'Green green grass of home',
                'Hallo Mary Lou',
                'Bye bye love',
                'Buona sera signorina',
                'Don\'t be cruel',
                'Knock on the Heaven\'s door',
                'Wonderul tonight'
            ]
        }
    ]
};