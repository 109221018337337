import React, {useState} from 'react';
import {Button, Icon, Menu, MenuItem, MenuMenu, Portal, Segment} from "semantic-ui-react";
import {SubnavProps} from "./SubnavProps";
import {Spacer} from "../../UI/Spacer";

const Trigger: React.FunctionComponent<{ onClick: () => void }> = (props) => (
    <Button color="blue" onClick={props.onClick}>
        <Icon name="list"/>
        Izbornik
    </Button>
);

export const MobileSubnav: React.FunctionComponent<SubnavProps> = (props) => {

    const [isOpen, setIsOpen] = useState(false);

    return (
        <MenuMenu position="right">
            <MenuItem>
                <Portal
                    open={isOpen}
                    trigger={<Trigger onClick={() => setIsOpen(!isOpen)}/>}
                >
                    <Segment basic inverted color="blue"
                             style={{height: '100vh', width: '100%', position: 'fixed', top: 0, left: 0}}
                             padded="very"
                    >
                        <Spacer size={2}/>
                        <Menu inverted color="blue" fluid vertical tabular="right">
                            {
                                props.items.map(item =>
                                    <MenuItem
                                        key={item.id}
                                        active={item.id === props.activeItem}
                                        onClick={() => {
                                            setIsOpen(false);
                                            props.onSelect(item);
                                        }}
                                    >
                                        <Button fluid>{item.display}</Button>
                                    </MenuItem>
                                )
                            }
                        </Menu>
                    </Segment>
                </Portal>
            </MenuItem>
        </MenuMenu>
    );
};