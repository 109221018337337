import React, {useRef} from 'react';
import {Button, MenuItem, MenuMenu} from "semantic-ui-react";
import {SubnavProps} from "./SubnavProps";

export const DesktopSubnav: React.FunctionComponent<SubnavProps> = (props) => {

    const buttonRef = useRef<Button>(null);

    return (
        <MenuMenu position="right">
            {props.items.map(navigationItemInfo => {
                return (
                    <MenuItem key={navigationItemInfo.id} fitted>
                        <Button
                            ref={buttonRef}
                            onClick={(e) => {
                                (e.target as any).blur();
                                props.onSelect(navigationItemInfo)
                            }}
                            active={props.activeItem === navigationItemInfo.id} color="blue"
                        >
                            {navigationItemInfo.display}
                        </Button>
                    </MenuItem>
                );
            })
            }
        </MenuMenu>
    );
};