import React from 'react';
import ReactPlayer from "react-player";

type Props = {
    id: string;
}

export const YoutubeEmbed: React.FunctionComponent<Props> = (props) => {
    return (
        <ReactPlayer url={'https://youtu.be/' + props.id} width={'100%'}/>
    );
};