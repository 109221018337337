import React, { useState } from 'react';
import { Button, Container, Menu, MenuItem, Visibility } from 'semantic-ui-react';
import { NavigationItem, NavigationItemInfo, navigationItems } from './navigation-items';
import { scroller } from 'react-scroll';
import { DesktopSubnav } from './Subnav/DesktopSubnav';
import { MobileSubnav } from './Subnav/MobileSubnav';
import { SubnavProps } from './Subnav/SubnavProps';
import { useMediaQuery } from 'react-responsive';

function scrollTo(element: string) {
    scroller.scrollTo(element, {
        duration: 1000,
        smooth: true,
        offset: -50
    });
}

const submenuNavigationItems: NavigationItemInfo[] = Object.keys(navigationItems)
    .filter(item => item !== NavigationItem.HERO)
    .map(item => navigationItems[item as NavigationItem]);

export const Navbar: React.FunctionComponent<{ activeItem: string }> = (props) => {
    const [hasScrolled, setHasScrolled] = useState(false);
    const isMobile = useMediaQuery({ query: '(max-width: 850px)' });

    const subnavProps: SubnavProps = {
        items: submenuNavigationItems,
        activeItem: props.activeItem,
        onSelect: item => scrollTo(item.id)
    };

    return (
        <Container text>
            <Visibility
                once={false}
                fireOnMount={true}
                onTopPassed={() => setHasScrolled(true)}
                onBottomPassedReverse={() => setHasScrolled(false)}
            />
            <Menu
                fixed="top" inverted color="blue" size="large" borderless
                style={{ borderBottom: hasScrolled ? '1px solid black' : 0 }}
            >
                <MenuItem>
                    <Button
                        color="teal"
                        onClick={() => scrollTo(navigationItems.hero.id)}
                    >
                        {navigationItems.hero.display}
                    </Button>
                </MenuItem>

                {isMobile ?
                    <MobileSubnav {...subnavProps} /> :
                    <DesktopSubnav {...subnavProps} />
                }

            </Menu>
        </Container>
    );
};
