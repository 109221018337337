import {members} from "../Members/member-data";

interface PhoneContact {
    name: string;
    phone: string;
}

const phones: PhoneContact[] = members.members
    .filter(member => !!member.phone)
    .map(member => ({name: member.name, phone: member.phone!}));

export const contact = {
    title: 'Kontakt',
    subtitle: 'Ne\'jte bit srameći',
    youtube: 'https://www.youtube.com/channel/UCebwhvSeLnKvGSfgMyhcwvw',
    facebook: 'https://www.facebook.com/pages/Viva-Band-Bjelovar-Bmp/1616876335198713?ref=hl',
    mail: 'viva.band.bjelovar@gmail.com',
    phones
};
