import React, { useState } from 'react';
import { Hero } from './Sections/Hero/Hero';
import { Members } from './Sections/Members/Members';
import { navigationItems } from './Nav/navigation-items';
import { Navbar } from './Nav/Navbar';
import { ScrollableSection } from './UI/ScrollableSection';
import { Showbusiness } from './Sections/Showbusiness/Showbusiness';
import { Videos } from './Sections/Videos/Videos';
import { Photos } from './Sections/Photos/Photos';
import { Songbook } from './Sections/Songbook/Songbook';
import { Contact } from './Sections/Contact/Contact';

function App() {

    const [activeItem, setActiveItem] = useState('');

    return (
        <div className="App">

            <Navbar activeItem={activeItem} />

            <ScrollableSection navigation={navigationItems.hero} onVisible={setActiveItem}>
                <Hero />
            </ScrollableSection>

            <ScrollableSection navigation={navigationItems.showbusiness} onVisible={setActiveItem}>
                <Showbusiness />
            </ScrollableSection>

            <ScrollableSection navigation={navigationItems.videos} onVisible={setActiveItem}>
                <Videos />
            </ScrollableSection>

            <ScrollableSection navigation={navigationItems.photos} onVisible={setActiveItem}>
                <Photos />
            </ScrollableSection>

            <ScrollableSection navigation={navigationItems.members} onVisible={setActiveItem}>
                <Members />
            </ScrollableSection>

            <ScrollableSection navigation={navigationItems.songbook} onVisible={setActiveItem}>
                <Songbook />
            </ScrollableSection>

            <ScrollableSection navigation={navigationItems.contact} onVisible={setActiveItem}>
                <Contact />
            </ScrollableSection>

        </div>
    );
}

export default App;
