import React from 'react';
import { Container, Image, Segment } from 'semantic-ui-react';
import { SectionHeader } from '../../UI/SectionHeader';
import Carousel from 'nuka-carousel';
import { photos } from './photos-data';

export const Photos: React.FunctionComponent<any> = () => {
    return (
        <Segment color="orange" basic padded>
            <SectionHeader title={photos.title} subtitle={photos.subtitle} />

            <Container>
                <Carousel
                    wrapAround={true}
                    cellAlign="center"
                    defaultControlsConfig={{ nextButtonText: '>', prevButtonText: '<' }}
                >
                    {photos.links.map(link =>
                        <Image src={link} key={link} centered />
                    )}
                </Carousel>
            </Container>
        </Segment>
    );
};
