import React from 'react';
import {SectionHeader} from "../../UI/SectionHeader";
import {Container, Grid, GridColumn, Segment} from "semantic-ui-react";
import {Expand} from "../../UI/Expand";
import {songbook} from "./songbook-data";

export const Songbook = () => {
    return (
        <Segment basic color="orange" padded>
            <SectionHeader title={songbook.title} subtitle={songbook.subtitle}/>
            <Container>
                {songbook.songs.map(group => (
                    <Expand title={group.category} key={group.category}>
                        <Grid stackable>
                            {
                                group.items.map((item, index) => (
                                    <GridColumn
                                        key={index}
                                        style={{padding: '0px !important'}}
                                        mobile={16}
                                        tablet={8}
                                        computer={8}
                                        largeScreen={5}
                                    >
                                        {item}
                                    </GridColumn>
                                ))
                            }
                        </Grid>
                    </Expand>
                ))}
                <Segment basic textAlign="center">
                    {songbook.footnote}
                </Segment>
            </Container>
        </Segment>
    );
}
