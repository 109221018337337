import {SemanticICONS} from "semantic-ui-react/src/generic";

type Quickie = {
    icon: SemanticICONS;
    title: string;
    description: string;
}

export const showbusiness: {
    intro: string;
    header: string;
    contentOne: string;
    contentTwo: string;
    contentThree: string;
    contentFour: string;
    quickies: Quickie[];
} = {
    intro: 'U potrazi za glazbom?',
    header: 'Na pravom ste mjestu!',
    contentOne: `Mi smo peteročlani glazbeni sastav koji već duže vrijeme zabavlja brojne goste na raznim veselim
                prigodama. Članovi banda dolaze sa područja Bilogore, Moslavine i Prigorja. Raspolažemo velikim
                repertoarom, pogotovo narodnim, ali kao stari glazbeni mački i uhodani bend za svadbe možemo
                zadovoljiti različite glazbene želje i ukuse po načelu za svakoga ponešto, čemu u prilog govore
                video zapisi s naših nastupa. Na našoj YouTube stranici nalazi se velik broj snimaka (i taj broj
                stalno raste) sa raznih svirki koji su snimljeni uživo i nisu naknadno montirani: znači, to je
                ono što možete očekivati i na vašoj proslavi.`,
    contentTwo: `U postavi banda nalazi se i harmonika. Kad sviramo zabavnu i rock glazbu, harmonikaš svira gitaru. 
                Ako slučajno nestane struje ili Vašoj svadbenoj povorci treba glazbena pratnja, prihvatit ćemo se starih 
                tambura i opet zasvirati za Vas.`,
    contentThree: `Sviramo puno, kvalitetno, raznovrsno i dinamično, uz kratke pauze poslije kojih nas ne morate
                    tražiti po obližnjim lokalima. U inventaru Viva Banda nalazi se mnoštvo instrumenata i različite
                    glazbene opreme, kao što su razglas i svjetlosni efekti . Zahvaljujući višegodišnjem iskustvu
                    možemo Vam pomoći u savjetima oko protokola, običaja i drugih pitanja koja su za Vas važna i nova. 
                    Uostalom, to i jest posao benda za svadbu.`,
    contentFour: `Kao i svi glazbeni sastavi, najviše se radujemo sviranju svadbenih svečanosti, no niti krstitke,
                    rođendan, zabava vašeg kluba, poduzeća ili udruge, maturalna večer ili bilo kakva druga proslava
                    nama nije mrska. Zahvaljujući tome i profesionalnom pristupu nastupima rijetki su vikendi koje
                    provedemo kod kuće. Ako se i dalje pitate je li Viva Band pravi izbor, nazovite nas i saznajte
                    sve što vas zanima!`,
    quickies: [
        {
            icon: 'glass martini',
            title: 'Zabavni',
            description: 'Sviramo zato što to volimo, a naši gosti i njihovo raspoloženje uvijek su u prvom planu'
        },
        {
            icon: 'music',
            title: 'Muzikalni',
            description: 'U našem se repertoaru nalaze mnoge zabavne, tamburaške, pop, narodne, i starogradske pjesme'
        },
        {
            icon: 'truck',
            title: 'Opremljeni',
            description: 'Da bismo svirali nije nam uvijek potrebna struja jer imamo i akustične instrumente'
        },
        {
            icon: 'info circle',
            title: 'Informativni',
            description: 'Nudimo pomoć i savjetovanje u vezi protokola, običaja i drugih bitnih pitanja'
        },
    ]
};