import React, { ReactNode, useRef } from 'react';
import {Visibility} from "semantic-ui-react";
import {NavigationItemInfo} from "../Nav/navigation-items";
import {Element} from "react-scroll/modules";

type Props = {
    navigation: NavigationItemInfo;
    onVisible: (navigationItemId: string) => void;
    children: ReactNode;
}

export const ScrollableSection: React.FunctionComponent<Props> = (props) => {

    const spyingDivRef = useRef<HTMLDivElement>(null);

    return (
        <Element name={props.navigation.id}>
            <Visibility
                onUpdate={(nothing, {calculations}) => {
                    if (calculations.offScreen) {
                        return;
                    }
                    if (calculations.fits) {
                        props.onVisible(props.navigation.id);
                    }
                    if (!!spyingDivRef.current) {
                        const boundingRect = spyingDivRef.current.getBoundingClientRect();
                        if (boundingRect.height > window.innerHeight
                            && boundingRect.y < 50
                            && (boundingRect.height + boundingRect.y > window.innerHeight / 3)
                        ) {
                            props.onVisible(props.navigation.id);
                        }
                    }
                }}
                once={false}
            >
                <div ref={spyingDivRef}>
                    {props.children}
                </div>
            </Visibility>
        </Element>
    );
};
