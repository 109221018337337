import React from 'react';
import {SectionHeader} from "../../UI/SectionHeader";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardMeta,
    Container,
    Grid,
    GridColumn,
    Image,
    Segment
} from "semantic-ui-react";

import {members} from "./member-data";

export const Members = () => {
    return (
        <Segment basic color="orange" padded>
            <SectionHeader title={members.title} subtitle={members.subtitle}/>
            <Container>
                <Grid centered stackable stretched columns={3}>
                    {members.members.map(member => (
                        <GridColumn key={member.name} tablet={16} computer={5}>
                            <Card fluid>
                                <Image centered src={member.photo}/>
                                <CardContent>
                                    <CardHeader>{member.name}</CardHeader>
                                    {!!member.phone && <CardMeta>{member.phone}</CardMeta>}
                                    <CardDescription>
                                        <div dangerouslySetInnerHTML={{__html: member.rawDescription}}/>
                                    </CardDescription>
                                </CardContent>
                            </Card>
                        </GridColumn>
                    ))}
                </Grid>
            </Container>
        </Segment>
    );
};
