import React from 'react';
import {Container, Divider, Grid, GridColumn, Header, Icon, Segment} from "semantic-ui-react";
import {showbusiness} from "./showbusiness-data";

export const Showbusiness: React.FunctionComponent<any> = () => {
    return (
        <Segment basic padded>
            <Container textAlign="center">
                <h3>{showbusiness.intro}</h3>
                <h1>{showbusiness.header}</h1>

                <Divider/>

                <Grid stackable columns={2} style={{fontSize: '1.2em'}}>
                    <GridColumn textAlign="justified">
                        <p>{showbusiness.contentOne}</p>
                        <p>{showbusiness.contentTwo}</p>
                    </GridColumn>
                    <GridColumn textAlign="justified">
                        <p>{showbusiness.contentThree}</p>
                        <p>{showbusiness.contentFour}</p>
                    </GridColumn>
                </Grid>

                <Divider/>

                <Grid stackable columns={4}>
                    {showbusiness.quickies.map(quickie => (
                        <GridColumn textAlign="center" key={quickie.title}>
                            <Segment basic>
                                <Header textAlign="center" as="h2">
                                    <Icon name={quickie.icon}/>
                                    {quickie.title}
                                </Header>
                                <p>{quickie.description}</p>
                            </Segment>
                        </GridColumn>
                    ))}
                </Grid>

            </Container>
        </Segment>
    );
};
