export const videos = {
    title: 'Snimke',
    subtitle: 'Pogledajte Vivu u akciji! Više videa možete pronaći na našem Youtube kanalu',
    youtubeIds: [
        'FGlgofHJKSI',
        'Q67BJO3xF1Q',
        '4hhW7q5P3hg',
        'tAvBH9SmNfI',
        'r6xANgbMdvc',
    ]
};
