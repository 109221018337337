import React from 'react';
import {Header, Segment} from "semantic-ui-react";

type Props = {
    title: string;
    subtitle: string;
    inverted?: boolean;
}

export const SectionHeader: React.FunctionComponent<Props> = (props) => {
    return (
        <Segment basic textAlign="center">
            <Header
                as="h1"
                inverted={props.inverted}
                content={props.title}
                subheader={props.subtitle}/>
        </Segment>
    );
};