import React, { useState } from 'react';
import { Accordion, AccordionContent, AccordionTitle, Icon } from 'semantic-ui-react';

type Props = {
    title: string;
    children: React.ReactNode;
}

export const Expand: React.FunctionComponent<Props> = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    return (

        <Accordion fluid styled>
            <AccordionTitle
                style={{ textAlign: 'center' }}
                active={isOpen}
                onClick={e => setIsOpen(!isOpen)}
            >
                <Icon name="dropdown" />
                {props.title}
            </AccordionTitle>
            <AccordionContent
                active={isOpen}
            >
                {props.children}
            </AccordionContent>
        </Accordion>
    );
};
