import acoustic from './Assets/acoustic.jpg';
import fio from './Assets/fio.png';
import moderno from './Assets/moderno.jpg';
import na_koljena from './Assets/na_koljena.png';
import visnja from './Assets/visnja.jpg';

export const photos = {
    title: 'Slike',
    subtitle: 'Neki od najboljih trenutaka Viva Banda ulovljeni kamerom!',
    links: [
        acoustic,
        fio,
        moderno,
        na_koljena,
        visnja,
    ],
};