import React from 'react';
import {Anchor} from "../../UI/Anchor";
import {Button, Icon, SemanticICONS} from "semantic-ui-react";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";

type Props = {
    href: string;
    icon: SemanticICONS;
    title: string;
}

export const ContactButton: React.FunctionComponent<Props> = (props) =>
    <Button color="blue">
        <Anchor href={props.href}>
            <Icon name={props.icon} size="huge" style={{color: 'white'}}/>
            <Header as="h4" inverted>{props.title}</Header>
        </Anchor>
    </Button>;