import React from 'react';
import { Container, Header, Image, Segment } from 'semantic-ui-react';
import { Uppercase } from '../../UI/Uppercase';
import cover from './Assets/cover.jpg';
import { useMediaQuery } from 'react-responsive';

const hero = {
    title: 'Viva Band Bjelovar',
    subtitle: 'Bend za proslave i vjenčanja',
    cover
};

export const Hero = () => {

    const isMobile = useMediaQuery({ query: '(max-width: 850px)' });
    const isComputer = useMediaQuery({ query: '(min-width: 851px)' });

    return (
        <>
            <Segment
                inverted color="blue" vertical
                style={{ padding: '6em 0 2em 0', borderBottom: '1px black solid' }}
                textAlign="center"
            >
                <Container text>

                    {isComputer &&
                        <Header inverted style={{ fontSize: '4em' }}>
                            <Uppercase>{hero.title}</Uppercase>
                        </Header>
                    }

                    {isMobile &&
                        <Header inverted style={{ fontSize: '2em' }}>
                            <Uppercase>{hero.title}</Uppercase>
                        </Header>
                    }

                    <Header inverted>
                        {hero.subtitle}
                    </Header>
                </Container>
            </Segment>
            <Image centered={true} src={hero.cover} />
        </>
    );
};
