import React from 'react';
import {Button, Grid, GridColumn, Header, Icon, Segment} from "semantic-ui-react";
import {SectionHeader} from "../../UI/SectionHeader";
import {YoutubeEmbed} from "../../UI/YoutubeEmbed";
import {contact} from "../Contact/contact-data";
import {videos} from "./videos-data";

export const Videos: React.FunctionComponent<any> = () => {
    return (
        <Segment basic color="orange" padded>
            <SectionHeader title={videos.title} subtitle={videos.subtitle}/>
            <Grid stackable stretched columns={3} textAlign="center">
                {videos.youtubeIds.map(youtubeId =>
                    <GridColumn key={youtubeId}>
                        <YoutubeEmbed id={youtubeId}/>
                    </GridColumn>
                )}

                <GridColumn verticalAlign="middle" textAlign="center">
                    <Button style={{height: '100%'}}>
                        <a target="_blank" href={contact.youtube} rel="noopener noreferrer" >
                            <Header as="h2" icon>
                                <Icon size="massive" name="youtube play" color="red"/>
                                Posjetite Vivu na Youtube-u!
                            </Header>
                        </a>
                    </Button>
                </GridColumn>

            </Grid>
        </Segment>
    );
};
