import React from 'react';
import { SectionHeader } from '../../UI/SectionHeader';
import { Container, Divider, Grid, GridColumn, Icon, Segment } from 'semantic-ui-react';
import { contact } from './contact-data';
import Header from 'semantic-ui-react/dist/commonjs/elements/Header';
import { Spacer } from '../../UI/Spacer';
import { Anchor } from '../../UI/Anchor';
import { ContactButton } from './ContactButton';
import { useMediaQuery } from 'react-responsive';

function toInternational(phoneNumber: string) {
    return phoneNumber.replace(/[^\d+]/g, '-')
        .replace(/^0/, '+385-');
}

export const Contact = () => {

    const isMobile = useMediaQuery({ query: '(max-width: 850px)' });
    const isComputer = useMediaQuery({ query: '(min-width: 851px)' });

    return (
        <Segment inverted color="blue" padded>
            <SectionHeader title={contact.title} subtitle={contact.subtitle} inverted />
            <Container>

                <Spacer size={2} />

                <Grid textAlign="center" padded stackable columns={2}>
                    {contact.phones.map(contactPhone =>
                        <GridColumn key={contactPhone.name}>
                            <Anchor href={'tel:' + toInternational(contactPhone.phone)}>
                                <Header inverted>
                                    <Icon name="phone" />
                                    {contactPhone.name} - {contactPhone.phone}
                                </Header>
                            </Anchor>
                        </GridColumn>
                    )}
                </Grid>

                {isComputer && <Spacer size={4} />}

                <Divider inverted />

                {isComputer && <Spacer size={4} />}

                <Grid stackable columns={4} centered stretched textAlign="center">
                    <GridColumn textAlign="center">
                        <ContactButton href={contact.youtube} icon="youtube" title="Posjetite naš Youtube kanal" />
                    </GridColumn>
                    <GridColumn textAlign="center">
                        <ContactButton href={contact.facebook} icon="facebook" title="Pratite nas na Facebooku" />
                    </GridColumn>
                    <GridColumn textAlign="center">
                        <ContactButton href={`mailto:${contact.mail}`} icon="mail" title="Napišite nam pismo" />
                    </GridColumn>
                </Grid>

                {isComputer && <Spacer size={8} />}

                {isMobile && <Spacer size={4} />}

            </Container>
        </Segment>
    );
};
