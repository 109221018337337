export type NavigationItemInfo = {
    id: string,
    display: string
}

export enum NavigationItem {
    HERO = 'hero',
    SHOWBUSINESS = 'showbusiness',
    VIDEOS = 'videos',
    PHOTOS = 'photos',
    MEMBERS = 'members',
    SONGBOOK = 'songbook',
    CONTACT = 'contact',
}

export const navigationItems: Record<NavigationItem, NavigationItemInfo> = {
    [NavigationItem.HERO]: {id: 'hero', display: 'Viva Band Bjelovar'},
    [NavigationItem.SHOWBUSINESS]: {id: 'showbusiness', display: 'Šoubiznis'},
    [NavigationItem.VIDEOS]: {id: 'videos', display: 'Snimke'},
    [NavigationItem.PHOTOS]: {id: 'photos', display: 'Slike'},
    [NavigationItem.MEMBERS]: {id: 'members', display: 'O nama'},
    [NavigationItem.SONGBOOK]: {id: 'songbook', display: 'Repertoar'},
    [NavigationItem.CONTACT]: {id: 'contact', display: 'Kontakt'},
};